.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #444440;
  box-shadow: 0 3px 6px #000;
}

.contact h4 {
  margin: 0;
}

.blank {
  width: 90px;
}

.wrap-logo {
  width: 130px;
  aspect-ratio: 1;
}
.img-logo {
  width: 100%;
}
