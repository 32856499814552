.otp-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp-input {
  margin: 5px;
  width: 30px;
  height: 50px;
  border: 1px solid #ff9327;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 5px;
}

.Button {
  padding: 10px 80px !important;
  color: #000 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.Button-disable {
  background-color: #bfbebe !important;
}

.Button-active {
  background-color: #5d8d66 !important;
}

.otp-form_field,
.otp-form_field:focus {
  border: none;
  background: #e5e5e5;
  padding: 20px 40px 20px 40px;
  min-width: 100%;
  box-sizing: border-box;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  outline: none;
}

.otp-form_field:focus-visible {
  outline: none;
  /*border: none;*/
  /*border-radius: 14px;*/
}

.buttonOk {
  width: 30%;
}

.wrapButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}
