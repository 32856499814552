.success-register-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.validateImage {
  width: 70px;
  aspect-ratio: 1;
}

.downloadText {
  width: 90%;
}

.qrCodeImage {
  width: 170px;
  aspect-ratio: 1;
}

.labelImage {
  width: 90px;
}

.wrap-qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-back {
  background-color: #a0b9b3 !important;
  border-color: transparent;
  color: #585151;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 360px) and (max-height: 640px) {
  .success-register-container {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 375px) and (max-height: 667px) {
  .success-register-container {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 375px) and (max-height: 812px) {
  .success-register-container {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 414px) and (max-height: 736px) {
  .success-register-container {
    padding-top: 15px;
  }
}
