.card {
    max-width: 50%;
    width: 40%;
    padding: 20px 0 20px 0;
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-header h2 {
    display: inline-block;
    letter-spacing: 3px;
}

.card-body {
    padding: 0 20px 0px 20px;
}

.card-content .content {
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 2px;
}

/*.card-content {*/
/*    border-bottom: 1px solid black;*/
/*}*/

.card-footer {
    margin: 20px 20px 0;
    padding: 20px 0;
    border-top: 1px solid black;
}

@media only screen and (max-width: 414px) {
    .card {
        min-width: 95%;
    }
}

@media (min-width: 415px) and (max-width: 1024px) {
    .card {
        min-width: 95%;
    }
}
