.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-white {
  color: #f6f6f6 !important;
}

.text-orange {
  color: #ff9327;
}

.text-gray {
  color: #7b8189 !important;
}

.text-red {
  color: #d63333;
}

.text-yellow {
  color: #f4d536;
}

.text-green {
  color: #55ad3b;
}

.text-light-green {
  color: #89a880;
}

.text-strike {
  text-decoration: line-through;
}

.text-large {
  font-size: 24px;
}

.text-medium {
  font-size: 18px !important;
}

.text-small {
  font-size: 14px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-normal {
  text-transform: none !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-underline {
  text-decoration: underline;
}

.text-500 {
  font-weight: 500 !important;
}

@media only screen and (max-width: 414px) {
  .text-large {
    font-size: 18px !important;
  }

  .text-medium {
    font-size: 14px !important;
  }

  .text-small {
    font-size: 12px !important;
  }
}
