/*MATERIAL UI CARD*/
.Card {
    border: 1px solid #FF9327 !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16) !important;
    border-radius: 20px !important;
}

.Card--normal {
    border: none !important;
}

.CardContent {
    padding-bottom: 16px !important;
}

.MuiTypography-body1 {
    font-weight: bold !important;
}
