.register-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.register-page-header {
  padding: 20px 80px 20px 10px;
  background: #ff9327;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 75%;
}

.register-page-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form_field,
.register-form_field:focus {
  border: 1px solid #fff;
  background: #f8f8f8;
  padding: 20px 40px 20px 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-appearance: none;
}

.register-form_field-error {
  border: 2px solid red !important;
}

.register-form_field-error:focus {
  box-shadow: none !important;
}

.register-form_field:focus {
  box-shadow: 0 0 0 2pt rgba(257, 147, 0, 1);
}

.register-form_field:focus-visible {
  outline: none;
  /*border: none;*/
  /*border-radius: 14px;*/
}

.form-group {
  width: 100%;
}

.suggest {
  /*margin-top: 10px;*/
  padding: 10px;
  border-bottom: 1px solid #7b8189;
}

.input-icon {
  position: absolute;
  right: 20px;
  margin-top: 20px;
}

.preview-pdf {
  width: 50%;
  margin: 0;
  text-align: right;
  font-size: 12px;
}

.wrap-preview-pdf {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
}
