.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    border-radius: 14px;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 1rem;
    height: 2.5rem;
    outline: none;
    padding: 5px 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-weight: bold;
    transition: .5s;
    width: fit-content;
}

.btn:hover {
    filter: brightness(110%);
}

.btn:active {
    transform: translate(0, 0.3rem);
}

.btn-orange {
    background: #FE9300 !important;
}

.btn-white {
    background: #fff;
}

.btn-gray {
    background: #E5E5E5;
}

.btn-border-orange {
    border: 1px solid #FE9300;
}


.btn-radio {
    border-radius: 14px;
    border: none;
    color: #C9C3C3;
    cursor: pointer;
    font-size: 1.2rem;
    height: 2.5rem;
    outline: none;
    padding: 15px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-weight: bold;
    transition: .5s;
    width: fit-content;
}

.btn-radio-choose {
    background: #FF9327;
    color: #000;
}

.radio-btn-group {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    align-items: center !important;
}
