.main-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
}

.main-page-header {
    padding: 10px 80px 10px 80px;
    background: #FF9327;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}


.main-page-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-control {
    line-height: 20px;
}

.form-control .option1 {
}


.form_field {
    border: 2px solid #FF9300;
    background: transparent;
    padding: 20px 40px;
    min-width: 100%;
    box-sizing: border-box;
}

.form_field:focus-visible {
    outline: none;
}

@media only screen and (max-width: 414px) {
    .main-page-header {
        padding: 20px 20px;
        width: 85%;
    }
}

.selected {
    border: 1px solid #FF9328 !important;
    background: #fff !important;
}

.promo-input {
    max-width: 271px;
}

.main-page-actions {
    width: 100%;
}
